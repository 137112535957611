<template>
  <div>
    <card-component :title="'Combined datasets “' + object.user_name + '”'" icon="table" >
      <div class='columns'>
        <div class="column">
          <b>Function : {{object.meta.func_name}}</b><br/>
          <b>Parameters: </b><br/>
          <ul class="prettylist">
            <li v-for="(value, key) in object.meta.func_args_for_print"
              v-bind:prop="value"
              v-bind:key="key"> <i>{{ key }}</i>: {{ value }}
            </li>
          </ul>
        </div>
        <div class="column">
            <template v-if="png">
              <png v-if="png" :data=png />
            </template>
            <template v-if="table">
              <basic-table v-if="table" :data="table"/>
            </template>
          </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import apiService from '@/services/apiService'
export default {
  name: 'CombinedView',
  props: {
    object: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      table: null,
      png: null
    }
  },
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'png': () => import('@/components/rresults/PNG.vue')
  },
  mounted () {
    if (this.object !== null) {
      this.refreshPreview()
    }
  },
  watch: {
    object: function () {
      this.refreshPreview()
    }
  },
  methods: {
    refreshPreview: function () {
      apiService.runRFunction({
        'func_name': 'r_preview_analysis',
        'nameAnalysis': this.object.object_name
      })
        .then(data => {
          this.png = null
          this.table = null
          if (data.plotfile) {
            this.png = data.plotfile
          }
          if (data.Table) {
            this.table = data.Table
          }
        })
        .catch(error => {
          this.error = error.data.error
        })
    }
  }
}
</script>
